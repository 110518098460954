module.exports = {
  current_environment: process.env.REACT_APP_ENV,

  api_static_host: process.env.REACT_APP_API_STATIC_HOST,
  api_endpoint: process.env.REACT_APP_API_ENDPOINT,

  debugLanguage: process.env.REACT_APP_DEBUG_LANGUAGE === "true",

  GA4_ID: process.env.REACT_APP_GA4_ID,
  GA4_ENABLED: process.env.REACT_APP_GA4_ENABLED === "true",
  GA4_LOGS_ENABLED: process.env.REACT_APP_GA4_LOGS_ENABLED === "true",

  hide_language_labels: process.env.REACT_APP_HIDE_LANGUAGE_LABELS === "true",

  orderDnaKitDescription: {
    img: process.env.REACT_APP_ORDER_DNA_KIT_DESCRIPTION_IMG,
  },

  resetPasswordLinkExpiry: parseInt(
    process.env.REACT_APP_RESET_PASSWORD_LINK_EXPIRY,
    10,
  ),

  mapboxConfig: {
    accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
    styleUrl: process.env.REACT_APP_MAPBOX_STYLEURL,
  },

  LOGROCKET_APP_ID: process.env.REACT_APP_LOGROCKET_APP_ID,

  APP_BASE_URL: process.env.REACT_APP_APP_BASE_URL,
  APP_NAME: process.env.REACT_APP_APP_NAME,
  PARTNER_NAME: process.env.REACT_APP_PARTNER_NAME,
  PARTNER_CONTACT: process.env.REACT_APP_PARTNER_CONTACT,
  CONSENT_LANGUAGE: process.env.REACT_APP_CONSENT_LANGUAGE,
  CONSENT_VIDEO_REQ_LENGTH: parseInt(
    process.env.REACT_APP_CONSENT_VIDEO_REQ_LENGTH,
    10,
  ),
  LANGUAGES: process.env.REACT_APP_LANGUAGES,
  DEFAULT_LANGUAGE: process.env.REACT_APP_DEFAULT_LANGUAGE,
  ENROLLMENT_BANNER_ENABLED:
    process.env.REACT_APP_ENROLLMENT_BANNER_ENABLED === "true",
  ENROLLMENT_BANNER_EXIT_ENABLED:
    process.env.REACT_APP_ENROLLMENT_BANNER_EXIT_ENABLED === "true",

  OTP_LIFETIME_SECS: parseInt(process.env.REACT_APP_OTP_LIFETIME_SECS, 10),
  REG_LINK_MIN: parseInt(process.env.REACT_APP_REG_LINK_MIN, 10),

  // Users can use a magic link to log in
  MAGIC_LINK_ENABLED: process.env.REACT_APP_MAGIC_LINK_ENABLED === "true",
  MAGIC_LINK_TOKEN_EXPIRY_MIN: parseInt(
    process.env.REACT_APP_MAGIC_LINK_TOKEN_EXPIRY_MIN,
    10,
  ),

  // ANCESTRY

  ANCESTRY_USER_AGREE: process.env.REACT_APP_ANCESTRY_USER_AGREE === "true",

  // REFERRALS
  // enabling referral banners in the app
  REFERRAL: process.env.REACT_APP_REFERRAL === "true",
  REFERRAL_ENROLLMENT_BANNER:
    process.env.REACT_APP_REFERRAL_ENROLLMENT_BANNER === "true",
  REFERRAL_DASHBOARD_BANNER:
    process.env.REACT_APP_REFERRAL_DASHBOARD_BANNER === "true",
  REFERRAL_SURVEY_BANNER:
    process.env.REACT_APP_REFERRAL_SURVEY_BANNER === "true",
  REFERRAL_QUICK_POLL_BANNER:
    process.env.REACT_APP_REFERRAL_QUICK_POLL_BANNER === "true",
  REFERRAL_LANDING_PAGE_BANNER:
    process.env.REACT_APP_REFERRAL_LANDING_PAGE_BANNER === "true",
  //sencondary referral for rutgers purpose
  REFERRAL_B_LANDING_PAGE: process.env.REACT_APP_REFERRAL_B_LANDING_PAGE,
  // SOCIAL MEDIA
  REFERRAL_SOCIAL_MEDIA: process.env.REACT_APP_REFERRAL_SOCIAL_MEDIA === "true",
  REFERRAL_SOCIAL_MEDIA_LINKEDIN:
    process.env.REACT_APP_REFERRAL_SOCIAL_MEDIA_LINKEDIN === "true",
  REFERRAL_SOCIAL_MEDIA_TWITTER:
    process.env.REACT_APP_REFERRAL_SOCIAL_MEDIA_TWITTER === "true",
  REFERRAL_SOCIAL_MEDIA_FACEBOOK:
    process.env.REACT_APP_REFERRAL_SOCIAL_MEDIA_FACEBOOK === "true",
  REFERRAL_SOCIAL_MEDIA_INSTAGRAM:
    process.env.REACT_APP_REFERRAL_SOCIAL_MEDIA_INSTAGRAM === "true",
  REFERRAL_SOCIAL_MEDIA_WHATSAPP:
    process.env.REACT_APP_REFERRAL_SOCIAL_MEDIA_WHATSAPP === "true",

  //REFERRAL Landing NewsLetter
  REFERRAL_LANDING_NEWSLETTER:
    process.env.REACT_APP_REFERRAL_LANDING_NEWSLETTER === "true",

  // Share by email or SMS button
  REFERRAL_EMAIL_SMS: process.env.REACT_APP_REFERRAL_EMAIL_SMS === "true",

  // Co-branding for the footer
  // if true, will use logo in src/assets/images/logo.png
  // if false, will not use a logo and only show the co-branding text
  COBRANDING_LOGO_ENABLED:
    process.env.REACT_APP_COBRANDING_LOGO_ENABLED === "true",
  // Set to 'none' to disable URL or text
  COBRANDING_URL: process.env.REACT_APP_COBRANDING_URL,
  COBRANDING_TEXT: process.env.REACT_APP_COBRANDING_TEXT,
  // Feature flags
  FF_WEBPAGE_FAQ: process.env.REACT_APP_FF_WEBPAGE_FAQ === "true",
  FF_WEBPAGE_CONTACT: process.env.REACT_APP_FF_WEBPAGE_CONTACT === "true",
  FF_WEBPAGE_SUPPORT: process.env.REACT_APP_FF_WEBPAGE_SUPPORT === "true",
  FF_WEBPAGE_RECRUITMENT:
    process.env.REACT_APP_FF_WEBPAGE_RECRUITMENT === "true",
  FF_WEBPAGE_DIVERSITY: process.env.REACT_APP_FF_WEBPAGE_DIVERSITY === "true",
  FF_WEBPAGE_FUNDING: process.env.REACT_APP_FF_WEBPAGE_FUNDING === "true",
  FF_WEBPAGE_BLOG: process.env.REACT_APP_FF_WEBPAGE_BLOG === "true",
  FF_WEBPAGE_NEWS: process.env.REACT_APP_FF_WEBPAGE_NEWS === "true",
  FF_WEBPAGE_VOLUNTEER: process.env.REACT_APP_FF_WEBPAGE_VOLUNTEER === "true",
  FF_WEBPAGE_WITHDRAWAL: process.env.REACT_APP_FF_WEBPAGE_WITHDRAWAL === "true",
  FF_WEBPAGE_PRIVACY_POLICY:
    process.env.REACT_APP_FF_WEBPAGE_PRIVACY_POLICY === "true",
  FF_WEBPAGE_DATA_PROTECTION:
    process.env.REACT_APP_FF_WEBPAGE_DATA_PROTECTION === "true",
  FF_WEBPAGE_GENETICS_101:
    process.env.REACT_APP_FF_WEBPAGE_GENETICS_101 === "true",
  FF_WEBPAGE_STUDY_PARTNERSHIPS:
    process.env.REACT_APP_FF_WEBPAGE_STUDY_PARTNERSHIPS === "true",
  FF_WEBPAGE_STUDY_TEAM: process.env.REACT_APP_FF_WEBPAGE_STUDY_TEAM === "true",
  FF_WEBPAGE_RESEARCH_CONSENT:
    process.env.REACT_APP_FF_WEBPAGE_RESEARCH_CONSENT === "true",
  FF_WEBPAGE_COOKIE_POLICY:
    process.env.REACT_APP_FF_WEBPAGE_COOKIE_POLICY === "true",
  FF_WEBPAGE_CORE_VALUES:
    process.env.REACT_APP_FF_WEBPAGE_CORE_VALUES === "true",
  FF_WEBPAGE_TOS: process.env.REACT_APP_FF_WEBPAGE_TOS === "true",
  FF_WEBPAGE_BANNER: process.env.REACT_APP_FF_WEBPAGE_BANNER === "true",
  FF_DISPLAY_ANCESTRY_TABLE_TABS:
    process.env.REACT_APP_FF_DISPLAY_ANCESTRY_TABLE_TABS === "true",
  FF_LANDING_PAGE_DATA_PROTECTION:
    process.env.REACT_APP_FF_LANDING_PAGE_DATA_PROTECTION === "true",
  FF_LANDING_PAGE_ANCESTRY_BANNER:
    process.env.REACT_APP_FF_LANDING_PAGE_ANCESTRY_BANNER === "true",
};
