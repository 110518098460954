import AncestryLandingPageHeader from "./ancestry-landing-page-header";
import AncestryLandingPageContent from "./ancestry-landing-page-content";
import styles from "./ancestry-landing-page.module.scss";
import { useEffect } from "react";

const AncestryLandingPage = () => {
  useEffect(() => {
    document.querySelector("body").scrollTo(0, 0);
  }, []);
  return (
    <div className={`${styles["ancestry-landing-page"]}`}>
      <AncestryLandingPageHeader />
      <AncestryLandingPageContent />
    </div>
  );
};

export default AncestryLandingPage;
