import ReferralLandingPageBHeader from "./referral-landing-page-b-header";
import ReferralLandingPageBDesktopContent from "./referral-landing-page-b-desktop-content";
import styles from "./referral-landing-page-b.module.scss";
import classNames from "classnames";
import ReferralLandingPageBMobileContent from "./referral-landing-page-b-mobile-content";
import React from "react";
import Sitemap_footer from "../../sitemap-footer/sitemap_footer";

export default function ReferralLandingPageB() {
  return (
    <>
      <div>
        <ReferralLandingPageBHeader />
        <div className={classNames(styles.referralLandingPageBMobileWrapper)}>
          <ReferralLandingPageBMobileContent />
          <Sitemap_footer />
        </div>
        <div className={classNames(styles.referralLandingPageBDesktopWrapper)}>
          <div className={classNames(styles.referralLandingPageBScrollable)}>
            <ReferralLandingPageBDesktopContent />
            <div className={classNames(styles.referralLandingPageBFooter)}>
              <Sitemap_footer />
              <div
                className={classNames(styles.referralLandingPageBFooterSpacer)}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

