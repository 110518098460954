import classNames from "classnames";
import { useTranslation, Trans } from "react-i18next";
import { ReferralLinkB } from "./referral-link-B";

import PAGES from "src/constants/pages";

import styles from "./landing-page-referral-banner-B.module.scss";

export function LandingPageReferralBannerB(props) {
  const { t } = useTranslation();
  return (
    <>
      <div className={classNames(styles.referralBannerWrapper, "rounded")}>
        <div
          className={classNames(
            styles.referralBanner,
            "position-relative overflow-hidden",
          )}
        >
          <div className={classNames(styles.description, "position-relative ")}>
            <Trans i18nKey="Landing_Referral_Banner_Title">
              <h3>
                Invite someone and help us lead discoveries to save lives.
              </h3>
            </Trans>
            <Trans i18nKey="Landing_Referral_Banner_Subtitle">
              <p>Together, we can make a difference. Invite someone now!</p>
            </Trans>
            <div className={classNames(styles.referralWrapper)}>
              <div className="pt-2 pb-2 flex-row flex-wrap referral-links-wrapper">
                <ReferralLinkB />
              </div>
              <div className={classNames(styles.referralButton)}>
                <div className="landing-hero-links">
                  <a href={PAGES.SIGNUP} className="landing-hero-button">
                    {t("Landing_Referral_Banner_Button")}
                  </a>
                </div>
              </div>
            </div>

            <div className="pt-3">
              <div className={classNames(styles.referralSmallButton)}>
                <div className="landing-hero-links">
                  <a
                    href={PAGES.SIGNUP}
                    className="landing-sm-banner-hero-button"
                  >
                    {t("Landing_Referral_Banner_Button")}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(styles.background)} />
        </div>
        <div className={classNames(styles.smallBannerBackground)} />
      </div>
    </>
  );
}
