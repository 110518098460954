import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ReferralLinkB } from "./referral-link-B";

import styles from "./enrollment-referral-banner.module.scss";
import HtmlContentComponent from "../core/HtmlContent/HtmlContentComponent";

export function EnrollmentReferralBanner() {
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.wrapper, "rounded mb-4")}>
      <h3 className={classNames(styles.title, "mb-4")}>
        {t("Invite someone and help us lead discoveries to save lives.")}
      </h3>
      <HtmlContentComponent
        className={classNames(styles.description, "mb-3")}
        markup={t("Together, we can make a difference. Invite someone now!")}
      />
      <div className={classNames(styles.link)}>
        <ReferralLinkB />
      </div>
    </div>
  );
}
