import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { CONFIG } from "../../constants/config";
import HtmlContentComponent from "../core/HtmlContent/HtmlContentComponent";
import selfCareImg from "../../assets/icons/self-care.svg";
import sequencingImg from "../../assets/icons/sequencing.svg";
import inviteOthersImg from "../../assets/icons/invite-others.svg";
import stayEngagedImg from "../../assets/icons/stay-engaged.svg";

import styles from "./enrollment_thank_you_banner.module.scss";

function ThankYouCard({ children }) {
  return <div className={styles.thankYouCard}>{children}</div>;
}

export function EnrollmentThankYouBanner({ onRequestContinue, onRequestExit }) {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        styles.thankYouBanner,
        "workflow-section workflow-banner bg-white p-4 rounded mb-4 text-center",
      )}
    >
      <img src={selfCareImg} alt="" />
      <h2 className="mb-5">
        {t("Thank You For Completing your Initial Enrollment")}
      </h2>
      <HtmlContentComponent
        className={classNames("mb-5 mx-3 mx-sm-5 mx-md-auto text-left", styles.thankYouText)}
        markup={t("How what you have done already helps...")}
      />
      <h2 className="mb-5">{t("What happens next")}</h2>
      <HtmlContentComponent
        className={classNames("mb-5 mx-3 mx-sm-5 mx-md-auto text-left", styles.thankYouText)}
        markup={t(
          "You’ll receive a saliva kit within X period of time. This is a crucial step...",
        )}
      />
      <h2 className="mb-5">
        {t("In the mean time, ways you can contribute further")}
      </h2>
      <div className={styles.thankYouCards}>
        <ThankYouCard>
          <img height="80" src={inviteOthersImg} alt="" />
          <h4>{t("Show n Tell")}</h4>
          <h3 className="text-dark">{t("Invite Others")}</h3>
          <HtmlContentComponent
            markup={t("Click on links below or within the app...")}
          />
        </ThankYouCard>
        <ThankYouCard>
          <img height="80" src={sequencingImg} alt="" />
          <h4>{t("About You")}</h4>
          <h3 className="text-dark">{t("Discover Insights")}</h3>
          <HtmlContentComponent
            markup={t("Take quick polls for instant comparisons...")}
          />
        </ThankYouCard>
        <ThankYouCard>
          <img height="80" src={stayEngagedImg} alt="" />
          <h4>{t("Help Others")}</h4>
          <h3 className="text-dark">{t("Stay Engaged")}</h3>
          <HtmlContentComponent
            markup={t(
              "You will request a saliva kit to provide your sample. Your DNA will be extracted",
            )}
          />
        </ThankYouCard>
      </div>
      <div className="text-right">
        <div>
          <Button
            className="primary mr-md-4 mb-1"
            onClick={() => onRequestContinue()}
          >
            {t("Continue to Contribute")}
          </Button>
        </div>
        {CONFIG.ENROLLMENT_BANNER_EXIT_ENABLED ? (
          <Button className="primary-invert mr-md-4" onClick={() => onRequestExit()}>
            {t("No thanks")}
          </Button>
        ) : null}
      </div>
    </div>
  );
}
